<template>
  <div>
    <div class="page">
      <van-cell-group title="影院基本信息">
        <van-cell title="影院编码" :value="cinema.CinemaCode" />
        <van-cell title="影院名称" :value="cinema.CinemaName" />
        <van-cell title="所属院线" :value="cinema.CinameChain" />
        <van-cell title="行政区划" :value="cinema.Zoning" />
        <van-cell title="影院地址" :value="cinema.Address" />
        <van-cell title="开业时间" :value="cinema.OpenTime" />
        <van-cell title="影院面积" value="m²" />
        <van-cell title="影院厅数" :value="cinema.ScreenCount" />
        <van-cell title="影院座位数" :value="cinema.SeatCount" />

        <van-cell title="影院经理" :value="cinema.ManagerName" />
        <van-cell title="影院经理联系方式" :value="cinema.ManagerPhone" />
      </van-cell-group>
      <van-cell-group title="企业信息">
        <van-cell title="企业名称" :value="cinema.CompanyName" />
        <van-cell title="社会统一信用代码" :value="cinema.CompanyCode" />
        <van-cell title="企业法人" :value="cinema.LegalName" />
        <van-cell title="企业法人电话" :value="cinema.LegalPhone" />
      </van-cell-group>
      <!--      <van-cell-group  title="经营规范">-->
      <!--        <van-cell title="放映许可证" value="20220401**1" />-->
      <!--        <van-cell title="许可证有效期" value="2021/03/1-2023/03/1"  />-->
      <!--      </van-cell-group>-->
    </div>
  </div>
</template>
<script>
import { zjApi as api } from '@/api/index'

export default {
  name: 'cinema-info',
  data() {
    return {
      cinemaCode: '',
      cinema: {},
    }
  },
  mounted() {
    console.log(this.$route.query)
    this.cinemaCode = this.$route.query.id
    if (this.cinemaCode) this.getCinemaInfo()
  },
  methods: {
    getCinemaInfo() {
      return new Promise((resolve, reject) => {
        const mobile = this.$store.state.userInfo.mobile
        const ps = `{"UserCode":"${mobile}","CmdIndex":"101003","cmdArgs":[${this.cinemaCode}]}`
        api
          .getPs({ ps: ps })
          .then((r) => {
            const { Success, data, Message } = r
            console.log('加载影院首页信息完毕')
            this.cinema = data
          })
          .catch((e) => console.log({ e }))
      })
    },
  },
}
</script>
<style lang="less" scoped>
.van-nav-bar {
  background: #5087ec;
  // 因为style标签上有 lang='less'  所以.van-nav-bar__title 可以嵌套到.van-nav-bar里面
  /* /deep/ 就是把data-v-hash值选择器写到类名的前面 */
  /deep/ .van-nav-bar__title {
    color: white;
  }
  /deep/ .van-icon,
  /deep/ .van-nav-bar__text {
    color: white;
  }
}
.page {
  //border: 1px solid red;
  padding: 0.1rem;
  background-color: rgb(255, 255, 255);
}
</style>
